/* eslint-disable max-len */
import { getSEO, PageSEO } from '@seo';
import { Link } from 'gatsby';
import React from 'react';

interface Props {
  location: {
    pathname: string;
  };
}

const CookiePolicy = ({ location }: Props) => {
  const { pathname } = location;
  const seo = getSEO(pathname);

  return (
    <>
      <PageSEO
        description={seo.description}
        featureImage="og-cookie-policy.jpg"
        pathname={pathname}
        title={seo.title}
      />
      <div className="max-w-2xl px-4 pt-16 pb-20 mx-auto text-base text-black lg:max-w-3xl xs:px-6 lg:pt-24 lg:pb-28 md:px-8 dark:text-white">
        <div className="mb-6">
          <h1 className="mb-4 text-5xl font-bold">Cookie Policy</h1>
          <p className="text-sm dark:text-gray-400">
            Last Modified: 12th September 2021
          </p>
        </div>

        <div className="leading-8">
          <p className="mb-4">This website stores cookies on your computer.</p>
          <p className="mb-4">
            These cookies are used to collect information about how you interact
            with our website and allow us to remember you.
          </p>
          <p className="mb-4">
            We use this information in order to improve and customize your
            browsing experience and for analytics and metrics about our visitors
            both on this website and other media.
          </p>

          <p className="mb-8">
            Our interactive websites use cookies to let us retrieve the user’s
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website.
          </p>

          <p>
            To find out more about the cookies we use, see our{' '}
            <Link className="inline-link" to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;
